
import './App.css';
import { BrowserRouter as Router, Routes, Route, Link} from 'react-router-dom'
import Home from './components/Home'
import About from './components/About'
import Inquiry from './components/Inquiry'
import Pricing from './components/Pricing'

function App() {
  return (
    <div className="container">
    <Router>
      <nav className='nav'>
        <div className='logodiv'>
          <img src='newsoftplaylogo.png' className='logoimg' alt='' />
        </div>
        <ul className='navlist'>
          <Link to='/' className='navitem navhome'>Home</Link>
          <Link to='/about' className='navitem'>About</Link>
          <Link to='/pricing' className='navitem'>Pricing</Link>
          <Link to='/inquiry' className='navitem'>Inquiry</Link>

        </ul>

      </nav>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/pricing' element={<Pricing />} />
        <Route path='/inquiry' element={<Inquiry />} />
      </Routes>
      </Router>

    </div>
  );
}

export default App;
