import React from 'react'


const Pricing = () => {
  return(
    <div>
    <h1 className='pricingtitle'>Pricing</h1>
    <div className='pricingsecdiv'>
      <div className='package l'>
        <h1 className='packagetitle'>Cobalt Black</h1>
        <img src='softplaybackyard.jpg' className='pricepageimg' alt='' />
        <p className='packagedes'>
          15x15x11 ft <br />
          $400 <br />
          Up to 5 hour rental. <br />
          Delivery may apply.
          </p>
          </div>
          <div className='package r'>
            <h1 className='packagetitle'>Cobalt Black</h1>
            <img src='softplaybackyard2.jpg' className='pricepageimg' alt='' />
            <p className='packagedes'>
              15x15x11 ft <br />
              $400 <br />
              Up to 5 hour rental. <br />
              Delivery may apply.
              </p>
              </div>
              <div className='package m'>
                <h1 className='packagetitle'>Cobalt Black</h1>
                <img src='softplaybackyardwhite.jpg' className='pricepageimg' alt='' />
                <p className='packagedes'>
                  15x15x11 ft <br />
                  $400 <br />
                  Up to 5 hour rental. <br />
                  Delivery may apply.
                  </p>
                  </div>
                  <div className='package l'>
                    <h1 className='packagetitle'>Cobalt Black</h1>
                    <img src='softplayfield.jpg' className='pricepageimg' alt='' />
                    <p className='packagedes'>
                      15x15x11 ft <br />
                      $400 <br />
                      Up to 5 hour rental. <br />
                      Delivery may apply.
                      </p>
                      </div>
                      <div className='package m'>
                        <h1 className='packagetitle'>Cobalt Black</h1>
                        <img src='softplayhouse.jpg' className='pricepageimg' alt='' />
                        <p className='packagedes'>
                          15x15x11 ft <br />
                          $400 <br />
                          Up to 5 hour rental. <br />
                          Delivery may apply.
                          </p>
                          </div>
                          <div className='package l'>
                            <h1 className='packagetitle'>Cobalt Black</h1>
                            <img src='softplaywhite.jpg' className='pricepageimg' alt='' />
                            <p className='packagedes'>
                              15x15x11 ft <br />
                              $400 <br />
                              Up to 5 hour rental. <br />
                              Delivery may apply.
                              </p>
                              </div>
                              <div className='package m'>
                                <h1 className='packagetitle'>Cobalt Black</h1>
                                <img src='softplaygroupkidshot.jpg' className='pricepageimg' alt='' />
                                <p className='packagedes'>
                                  15x15x11 ft <br />
                                  $400 <br />
                                  Up to 5 hour rental. <br />
                                  Delivery may apply.
                                  </p>
                                  </div>
                                  <div className='package r'>
                                    <h1 className='packagetitle'>Cobalt Black</h1>
                                    <img src='softplaykidshot.jpg' className='pricepageimg' alt='' />
                                    <p className='packagedes'>
                                      15x15x11 ft <br />
                                      $400 <br />
                                      Up to 5 hour rental. <br />
                                      Delivery may apply.
                                      </p>
                                      </div>
                                      <div className='package m'>
                                        <h1 className='packagetitle'>Cobalt Black</h1>
                                        <img src='softplayp&w.jpg' className='pricepageimg' alt='' />
                                        <p className='packagedes'>
                                          15x15x11 ft <br />
                                          $400 <br />
                                          Up to 5 hour rental. <br />
                                          Delivery may apply.
                                          </p>
                                          </div>
                                          <div className='package l'>
                                            <h1 className='packagetitle'>Cobalt Black</h1>
                                            <img src='softplayp&phorses.jpg' className='pricepageimg' alt='' />
                                            <p className='packagedes'>
                                              15x15x11 ft <br />
                                              $400 <br />
                                              Up to 5 hour rental. <br />
                                              Delivery may apply.
                                              </p>
                                              </div>
        </div>
    </div>
  )
}


export default Pricing
