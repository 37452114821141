import React from 'react'



const Home = () => {
  return(
    <div className='homecontainer'>
      <div className='softplaykidshotdiv'>
        <img src='softplaykidshot.jpg' alt='' className='softplaykidshot'/>
      </div>
      <h1 className='title-text'>Softplay Philly</h1>
      <div className='titleparagraphdiv'>
      <p className='titleparagraph'>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit,
        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
        voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
        occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
        anim id est laborum.
      </p>

      </div>
      <div className='jkydiv'>
      <img src='jockeyillustration.png' className='jkyleft' alt='' />
      <img src='girlhorse.png' className='jkyright' alt='' />
      </div>
      <div className='threecolumnsec'>
        <div className='row'>
          <div className='column firstc'>
            <h2>Social Skills</h2>
            <hr/>
          </div>
          <div className='column midc'>
            <h2>Creativity</h2>
            <hr/>
          </div>
          <div className='column lastc'>
            <h2>Motor Skills</h2>
            <hr/>
          </div>
        </div>
      </div>
      <h1 className='clientsloveh1'>Client's Love</h1>
      <h2 className='quotecharacter'>"</h2>
      <div className='quotediv'>
      <p className='clientquote'>Lorem ipsum dolor sit amet, consectetur adipisicing elit,
      sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
      Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
      </p>
      </div>
    </div>
  )
}


export default Home
