import React from 'react'
import { useState } from 'react'



const Inquiry = () => {

const packages = {standard: {price: 325, title: 'STANDARD PACKAGE', description: 'BALL PIT SAFETY GATES PLAY MATS TUNNEL SLIDE 3 BOUNCY TOY'},
premium: {price: 400, title: 'PREMIUM PACKAGE', description: 'BALL PITSAFETY GATESPLAY MATSSOFT PLAY TUNNELSOFT PLAY CLIMBERBUILDING BLOCKSSLIDEBASKETBALL HOOP3 BOUNCY TOYS'},
deluxe: {price: 475, title: 'DELUXE PACKAGE', description: 'BALL PIT SAFETY GATES PLAY MATS SOFT PLAY TUNNEL SOFT PLAY CLIMBER BUILDING BLOCKS SLIDE BASKETBALL HOOP 3 BOUNCY TOY BOUNCE HOUSE'},
lux: {price: 500, title: 'LUX PACKAGE', description: 'WHITE BALL PITSAFETY GATES 10X10 WHITE BOUNCE HOUSE 4 BOUNCY TOYS'},
arch: {price: 175, title: 'BALLOON ARCH', description: '10FT BALLOON ARCH ON BOUNCE HOUSE'}

}



const date = new Date()
const array = [...Array(6).keys()]
console.log(array)
console.log(date)

let total = 0



const [archcheck, setArchCheck] = useState(false)
const [luxcheck, setLuxCheck] = useState(false)
const [deluxecheck, setDeluxeCheck] = useState(false)
const [premiumcheck, setPremiumCheck] = useState(false)
const [standardcheck, setStandardCheck] = useState(false)
const handleChange = () => {
  setStandardCheck(!standardcheck)
}
const handleLuxChange = () => {
  setLuxCheck(!luxcheck)
}
const handleDeluxeChange =() => {
  setDeluxeCheck(!deluxecheck)
}
const handlePremiumChange = () => {
  setPremiumCheck(!premiumcheck)
}
const handleArchChange = () => {
  setArchCheck(!archcheck)
}
standardcheck? total += packages.standard.price : total += 0
luxcheck? total += packages.lux.price : total += 0
deluxecheck? total += packages.deluxe.price : total += 0
premiumcheck? total += packages.premium.price : total += 0
archcheck? total += packages.arch.price : total += 0
  return(
    <div className='inquiryformcontainer'>
    <h1>Inquiry</h1>
    <hr />
    <ul className='inquirylistul'>
      <li className='inquiryheadlist'>SOFTPPLAY RENTAL IS A STANDARD 4HRS</li>
      <li className='inquiryheadlist'>SET UP WILL START 1-2HR PRIOR TO THE EVENT STARTING TIME</li>
      <li className='inquiryheadlist'>A $125 NON-REFUNDABLE DEPOSIT IS REQUIRED TO SECURE YOUR DATE</li>
      <li className='inquiryheadlist'>A $150 REFUNDABLE DEPOSIT WILL BE ADDED TO FINAL TOTAL AND WILL BE RETURNED WITHIN 48 HRS ONCE WE
      ENSURE THERE IS NOT ANY LASTING DAMAGE TO EQUIPMENT. </li>
      <li className='inquiryheadlist'>
      THE STANDARD DELIVERY FEE IS $25 AND WILL VARY BASED ON LOCATION
      </li>

    </ul>
    <div className='inquiryformbackground'>
    <form action="https://formsubmit.co/58a50078c25ee564b0cd089867ecd035" method="POST" data-netlify='true' name='inquiryform'>
    <label for='event date' className='label'>Event Date</label><br />
    <input type='datetime-local' className='inputbar' id='eventdate' name='event date' /><br />
    <label for='first name' className='form-label label'>First Name:</label><br />
    <input type="text" className='form-control inputbar' id="fname" name="first name" placeholder='First Name' /><br />
    <label for='last name' className='label'>Last Name:</label><br />
    <input type="text" className='inputbar' id="lname" name="last name" placeholder='Last Name' /><br />
    <label for='email' className='label'>Email:</label><br />
    <input type="email" className='inputbar' id="email" name="email" placeholder='Email' /><br />
    <label for='phonenumber' className='label'>Phone Number:</label><br />
    <input type="text" className='inputbar' id="phonenumber" name="phonenumber" placeholder='Phone Number' /><br />
    <label for='contactmethod' className='label'>Preferred Contact Method</label><br />
    <input type="checkbox" id="contacttxt" name="preferred contact method" value="txt" />
    <label for='contacttxt' className='label'>txt</label><br />
    <input type="checkbox" id="contactcall" name="preferred contact method" value="call" />
    <label for='contactcall' className='label'>call</label><br />
    <input type="checkbox" id="contactvoicemsg" name="preferred contact method" value="voice message" />
    <label for='contactvoicemsg' className='label'>Voice Message</label><br />
    <input type="checkbox" id="contactemail" name="preferred contact method" value="email" />
    <label for='contactemail' className='label'>Email</label><br />
    <h1>Packages</h1>
    <div className='packagepricediv standard'>
    <input type='checkbox' onChange={handleChange} id='standardpackage' name='standardpackage' />
    <label for='standardpackage' className='label'><div className='upperpackagedescription'><h1>{packages.standard.title}</h1><h1 className='sp'>${packages.standard.price}</h1></div><div className='lowerpackagedescription'><p>{packages.standard.description}</p></div></label>
    </div>

    <div className='packagepricediv premium'>
    <input type='checkbox' onChange={handlePremiumChange} id='premiumpackage' name='premiumpackage' />
    <label for='premiumpackage' className='label'><div className='upperpackagedescription'><h1>{packages.premium.title}</h1><h1 className='pp'>${packages.premium.price}</h1></div><div className='lowerpackagedescription'><p>{packages.premium.description}</p></div></label>
    </div>

    <div className='packagepricediv deluxe'>
    <input type='checkbox' onChange={handleDeluxeChange} id='deluxepackage' name='deluxepackage' />
    <label for='deluxepackage' className='label'><div className='upperpackagedescription'><h1>{packages.deluxe.title}</h1><h1 className='dp'>${packages.deluxe.price}</h1></div><div className='lowerpackagedescription'><p>{packages.deluxe.description}</p></div></label>
    </div>


    <div className='packagepricediv lux'>
    <input type='checkbox' onChange={handleLuxChange} id='luxpackage' name='luxpackage' />
    <label for='luxpackage' className='label'><div className='upperpackagedescription'><h1>{packages.lux.title}</h1><h1 className='lp'>${packages.lux.price}</h1></div><div className='lowerpackagedescription'><p>{packages.lux.description}</p></div></label>
    </div>


    <div className='packagepricediv arch'>
    <input type='checkbox' onChange={handleArchChange} id='archpackage' name='archpackage' />
    <label for='archpackage' className='label'><div className='upperpackagedescription'><h1>{packages.arch.title}</h1><h1 className='ap'>${packages.arch.price}</h1></div><div className='lowerpackagedescription'><p>{packages.arch.description}</p></div></label>
    </div>

    <hr />
    <input type='hidden' name='total' value={total} />
    <h1>Total {total}</h1>

    <input type='submit' id='submit' name='submit' />

    </form>


    </div>

    </div>
  )
}




// <iframe src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FNew_York&src=dGlzbG1hbm9yMkBnbWFpbC5jb20&src=YWRkcmVzc2Jvb2sjY29udGFjdHNAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&color=%23039BE5&color=%2333B679" frameborder="0" scrolling="no" title='title' className='googlecal'></iframe>


//     // <form action=''

export default Inquiry
