import React from 'react'


const About = () => {
  return(
    <div className='aboutdiv'>
    <h1 className='abouttitle'>About Us</h1>
    <br />
    <p className='paragraph aboutp'>
    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
    aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
    voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
    occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
    anim id est laborum.
    </p>
    </div>
  )
}


export default About
